<template>
  <div class="pop">
    <div @click="toggleFontsDropdown" class="selected-wrap">
      <span class="current-font-label">{{ selectedName }}</span>
      <span class="pop-arrow">
        <svg-icon name="chevron-down" />
      </span>
    </div>

    <simplebar v-show="isFontsDropdownOpen" class="combo" v-click-outside="hideFontsDropdown">
      <ul class="font-list">
        <li
          v-for="(font, i) in getFonts()"
          :key="i + 1"
          @click="handleSelect(font)"
          :class="{ selected: font.name === selectedName }"
        >
          <div class="font-sample" :style="{ fontFamily: font.fontFamily }">{{ font.name }}</div>
        </li>
      </ul>
    </simplebar>
  </div>
</template>

<script>
import fonts from '~/assets/fonts';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  components: {
    simplebar,
  },

  props: {
    selected: {
      type: String,
    },
  },

  data() {
    return {
      isFontsDropdownOpen: true,
      selectedName: this.getSelectedName(),
    };
  },

  watch: {
    selected() {
      this.selectedName = this.getSelectedName();
    },
  },

  updated() {
    if (this.isFontsDropdownOpen) {
      // Scroll to the selected font
      [...document.querySelectorAll('.font-list .font-sample')]
        .filter(label => label.textContent == this.selectedName)
        .forEach(label => {
          document.querySelector('.combo').scrollTop = label.parentNode.offsetTop;
        });
    }
  },

  methods: {
    getSelectedName() {
      const selected = this.getFonts().filter(font => {
        return font.fontFamily == this.selected;
      });

      return selected.length ? selected[0].name : '';
    },

    getFonts() {
      return fonts;
    },

    openFontsDropdown() {
      this.isFontsDropdownOpen = true;
    },

    toggleFontsDropdown() {
      this.isFontsDropdownOpen = !this.isFontsDropdownOpen;
    },

    hideFontsDropdown(event) {
      const isClickOnSelected = event.target.closest('.selected-wrap');
      const isClickOnDropdown = event.target.closest('.combo');

      if (!isClickOnSelected && !isClickOnDropdown) {
        this.isFontsDropdownOpen = false;
      }
    },

    handleSelect(font) {
      this.isFontsDropdownOpen = false;
      this.$emit('select', font);

      this.selectedName = font.name;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.settings-popup {
  padding: 19px 12px 17px 12px;
  margin: 0 11px;

  > .popup-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 3px 0;
    padding: 0;
    color: #2b5672;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;
    width: calc(100% - 20px);
  }

  > .pop {
    position: relative;
    cursor: pointer;

    > .selected-wrap {
      position: relative;
      box-sizing: border-box;
      color: #162d3d;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      min-height: 36px;
      padding: 0 30px 0 0;
      margin: 0;

      .current-font-label {
        font-size: 18px;
        padding-right: 30px;
        color: #162d3d;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        height: 36px;
        line-height: 36px;
        min-height: 36px;
        display: inline;
      }

      .pop-arrow {
        border: 2px solid $primary;
        border-radius: 50%;
        content: '';
        display: block;
        height: 22px;
        opacity: 0.6;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;

        .icon {
          fill: $primary;
          left: 50%;
          position: absolute;
          top: 50%;
          margin-top: 0;
          transform: translate(-50%, -50%);
          height: 0.7em;
          width: 0.7em;
        }
      }
    }

    .combo {
      border-radius: 8px;
      box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.26);
      box-shadow: 0 0 18px 0 rgba(22, 45, 61, 0.27);
      position: fixed;
      width: 240px;
      max-height: 350px;
      background-color: $white;
      z-index: 100;
      overflow-y: auto;

      .font-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          box-sizing: border-box;
          cursor: pointer;
          display: block;
          font-size: 1rem;
          height: 36px;
          line-height: 36px;
          padding: 0 22px;
          position: relative;

          &:first-child {
            border-radius: 8px 0 0 0;
          }
          &:last-child {
            border-radius: 0 0 0 8px;
          }

          &:hover {
            background-color: rgba($primary, 0.3);
          }
          &.selected {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }
}
</style>
