<template>
  <div class="ai-image__wrapper">
    <div>
      <transition>
        <div v-show="loading" class="loader-container">
          <div class="loader"></div>
        </div>
      </transition>
      <div class="ai-image__btn_wrapper">
        <button @click="generateImage" class="btn btn-display">&#x1F4F7; Generate Image</button>
      </div>

      <div class="ai-image__content_wrapper">
        <div>
          <div class="ai-image__image mb-3">
            <div class="input_wrapper">
              <input
                v-model="prompt"
                @keypress.enter="generateImage"
                type="text"
                placeholder="🖊️ Enter your image description"
              />
            </div>
            <div v-if="image" class="img-box">
              <drag
                @dragstart="handleDragStart"
                :transfer-data="{
                  type: 'image',
                  platform: 'ai-image',
                  data: image,
                }"
                class="image-item"
                @dragend="handleDragEnd"
              >
                <img :src="image.imageUrl" alt="ai-image" />
              </drag>
            </div>
            <div v-else class="no-image d-flex align-items-center justify-content-center">
              <div>
                <span>Click the "Generate Image" button to get started</span>
              </div>
            </div>
          </div>
          <div>
            <button @click="useImage" class="ai-image__use_image btn ">Use</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AI_IMAGE_MESSAGE_MUTATION } from '~/graphql/mutations';
import { apolloClient } from '~/plugins/apollo';
import { Drag } from 'vue-drag-drop';
export default {
  name: 'AiImageGenerator',

  data() {
    return {
      loading: false,
      image: null,
      imageBlob: null,
      prompt: '',
      thumb: '',
      generatedImages: [],
      // image: 'https://picsum.photos/id/237/300/300',
    };
  },

  components: { Drag },

  methods: {
    getId() {
      return Date.now();
    },
    handleDragStart() {
      this.$store.dispatch('postEditor/updateIsDragging', true);
    },

    handleDragEnd({ data }, e) {
      this.$emit('dragged', { data, x: e.x, y: e.y });
      this.$store.dispatch('postEditor/updateIsDragging', false);
    },

    handleTemplateSelect(template) {
      this.$emit('templateSelect', template);
    },

    saveImage(imgData) {
      const binaryData = atob(imgData);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const view = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        view[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: 'image/png' });
      const link = URL.createObjectURL(blob);

      this.imageBlob = blob;

      return link;
    },

    useImage() {
      this.$emit('selectImage', this.image);
    },

    async prepareImage(imageData = '') {
      const imgUrl = this.saveImage(imageData);
      return {
        imageUrl: await this.loadImage(imgUrl),
        __typename: 'ai-image',
        id: this.getId(),
        authorName: 'AI',
        thumb: await this.generateThumb(imgUrl),
      };
    },

    async generateThumb(imageObjectUrl = '') {
      return new Promise((resolve, reject) => {
        const image = new Image();
        const canvas = document.createElement('canvas');

        image.src = imageObjectUrl;
        image.onload = function() {
          const ctx = canvas.getContext('2d');
          const aspectRatio = image.width / image.height;
          const thumbnailWidth = 100;
          const thumbnailHeight = thumbnailWidth / aspectRatio;

          canvas.width = thumbnailWidth;
          canvas.height = thumbnailHeight;
          ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);
          resolve(canvas.toDataURL());
        };

        image.onerror = function(e) {
          reject({ error: e, message: 'Error forming thumbnail', canvas, image });
        };
      });
    },

    async loadImage(imageData = null, thumb = false) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        const canvas = document.createElement('canvas');

        image.src = imageData;
        image.onload = function() {
          const ctx = canvas.getContext('2d');
          const aspectRatio = image.width / image.height;
          const thumbnailWidth = thumb ? 100 : image.width;
          const thumbnailHeight = thumbnailWidth / aspectRatio;

          canvas.width = thumbnailWidth;
          canvas.height = thumbnailHeight;
          ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);
          resolve(canvas.toDataURL());
        };

        image.onerror = function(e) {
          reject({ error: e, message: 'Error forming image', canvas, image });
        };
      });
    },

    async generateImage() {
      try {
        this.loading = true;
        const reply = await this.aiAgent({
          message: this.prompt,
        });

        if (reply.aiImage?.response[0] === "I am so sorry, I can't help you at the moment") {
          throw new Error(reply.aiImage?.response[0]);
        }
        this.image = await this.prepareImage(reply.aiImage?.response[0]);
      } catch (error) {
        alert('something went wrong');
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async aiAgent({ message }) {
      const data = await apolloClient.mutate({
        mutation: AI_IMAGE_MESSAGE_MUTATION,
        variables: {
          message,
        },
      });

      return data?.data;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/scss/variables';
.ai-image {
  &__use_image {
    background: $success;
    color: white;
    font-weight: bold;
    border-radius: var(--br) var(--br) 0px 0px;
  }
  &__wrapper {
    --br: 10px;
    position: relative;
    transition: all 0.3s;
    z-index: 0;
    max-width: 500px;
    transition: all 0.3s;

    & > div {
      background: white;
      border-radius: var(--br);
      // padding: 15px;
      padding-inline: 15px;
    }
  }

  &__btn_wrapper {
    display: inline-block;
    & .btn.btn-display {
      background: rgb(177, 131, 6);
      color: white;
      font-weight: bold;
      border-radius: 0px 0px var(--br) var(--br);
    }
  }

  &__content_wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-inline: 10px;

    & .no-image {
      background: rgba(0, 0, 0, 0.1);
      padding: 20px;
      color: black;
      border-radius: var(--br);
    }

    & .input_wrapper {
      margin-bottom: 10px;
      & input {
        width: 100%;
        appearance: none;
        background: $body-bg;
        border: none;
        outline: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: calc(var(--br) - 2px);
        padding: 10px;
      }
    }

    & .img-box {
      width: 100%;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
      aspect-ratio: 1/1;
      overflow: hidden;
      border-radius: var(--br);

      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }
    }
  }
}

.loader-container {
  overflow: hidden;
  width: calc(100% + 15px);
  transform: translateX(-7.5px);
  height: 3px;
  background: rgba(177, 131, 6, 0.327);
}

.loader {
  animation: scroll 2s linear infinite;
  white-space: nowrap;
  width: 40%;
  height: 3px;
  background: rgb(177, 131, 6);
}

@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(250%);
  }
}
</style>
